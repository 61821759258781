import {BaseRequest} from "@/request/BaseRequest";

class FirstPage extends BaseRequest {

    public requestPath: string = '/shop/first_page';

    /** ******************banner********************/

    /**
     * 获取banner
     * @param id
     */
    getBannerList(id: string): any {
        return this.listWithUrl(`${this.requestPath}/bannerList`, {id: id})
    }

    /**
     *  保存banner
     * @param data
     */
    saveBanner(data: any): any {
        return this.saveWithUrl(`${this.requestPath}/bannerSaveOrUpdate`, data);
    }

    /**
     * 删除banner
     * @param id
     */
    delBanner(id: string): any {
        return this.delWithUrl(`${this.requestPath}/bannerDel`, [id])
    }

    /** ******************商品********************/

    /**
     * 获取商品
     * @param id
     */
    getGoodsList(id: string): any {
        return this.listWithUrl(`${this.requestPath}/goodsList`, {id: id})
    }

    /**
     * 新增或保存商品
     * @param data
     */
    saveGoods(data: any): any {
        return this.saveWithUrl(`${this.requestPath}/goodsSaveOrUpdate`, data);
    }

    /**
     * 商品重新排序
     * @param id
     * @param sort
     */
    updateGoodsSort(id: string, sort: number) {
        return this.saveWithUrl(`${this.requestPath}/goodsSort`, {id: id, sort: sort});
    }

    /**
     *
     * @param id
     */
    deleteGoods(id: string): any {
        return this.delWithUrl(`${this.requestPath}/goodsDel`, [id]);
    }

    /** ******************分类********************/

    /**
     * 获取分类列表
     * @param id
     */
    getCategoryList(id: string): any {
        return this.listWithUrl(`${this.requestPath}/categoryList`, {id: id});
    }

    /**
     * 保存分类
     * @param data
     */
    saveCategory(data: any): any {
        return this.saveWithUrl(`${this.requestPath}/categorySaveOrUpdate`, data);
    }

    /**
     * 类别排序
     * @param id
     * @param sort
     */
    updateCategorySort(id: string, sort: number): any {
        return this.saveWithUrl(`${this.requestPath}/categorySort`, {id: id, sort: sort});
    }

    /**
     * 删除分类
     * @param id
     */
    deleteCategory(id: string): any {
        return this.delWithUrl(`${this.requestPath}/categoryDel`, [id]);
    }

    /**
     * 获取活动/广告列表
     * @param id
     */
    getAdvertiseList(id: string): any {
        return this.listWithUrl(`${this.requestPath}/advertiseList`, {id: id})
    }

    /**
     * 保存活动/广告
     * @param data
     */
    saveAdvertise(data: any): any {
        return this.saveWithUrl(`${this.requestPath}/advertiseSaveOrUpdate`, data)
    }

    /**
     * 删除活动/广告
     * @param id
     */
    deleteAdvertise(id: string): any {
        return this.delWithUrl(`${this.requestPath}/advertiseDel`, [id])
    }

    /** 名片****/

    /**
     * 获取名片列表
     */
    getCardListById(id: string): any {
        return this.listWithUrl(`${this.requestPath}/cardList`, {id: id})
    }

    /**
     * 删除名片
     * @param ids
     */
    deleteCardById(ids: string): any {
        return this.delWithUrl(`${this.requestPath}/cardDel`, [ids])
    }

    /**
     * 名片保存更新
     * @param data
     */
    saveOrUpdateCard(data: {
        employeeId: string,
        pageId: string,
        sort?: 1,
        defaultRecommend?: number
    }): any {
        return this.saveWithUrl(`${this.requestPath}/cardSaveOrUpdate`, data)
    }

    /**
     * 排序
     * @param id
     * @param sort
     */
    updateSort(id: string, sort: number): any {
        return this.post(`${this.requestPath}/cardSort`, {id: id, sort: sort})
    }

}

const c = new FirstPage();
export {c as FirstPage};