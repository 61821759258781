
































import {FirstPage} from "@/request/shop/firstPage";
import el_add_category from '@/components/first-page-components/el-add-category.vue'

export default {
    name: "Category1List",
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            list: []
        }
    },
    components: {
        'el-add-category': el_add_category
    },
    created() {
        let self: any = this;
        self.getData();
    },
    methods: {
        add() {
            let self: any = this;
            self.$refs['el-add-category'].show({id: ''});
        },
        getData() {
            let self: any = this;
            FirstPage.getCategoryList(self.id).then((body: any) => {
                if (body['code'] === 0) {
                    self.list = body.data;
                }
            })
        },
        onSubmit(row: any) {
            let self: any = this;
            let params = {
                pageId: self.id,
                categoryId: row.id,
                image: row.image,

            };
            FirstPage.saveCategory(params).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                }
            })
        },
        remove(id: string) {
            let self: any = this;
            FirstPage.deleteCategory(id).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                }
            })
        },
        update(row: any) {
            let self: any = this;
            FirstPage.updateCategorySort(row.id, row.sort).then((body: any) => {
                if (body['code'] === 0) {
                    self.getData();
                }
            })
        },
        onPopperShow() {
            let self: any = this;
            self.$forceUpdate();
        }
    }
}
