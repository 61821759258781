
































import {Category} from "@/request/goods/Category";

export default {
    name: "el-add-category",
    data() {
        return {
            row: {
                id: '',
                image: ''
            },
            addModal: {
                show: false,
                loading: false,
                searchLoading: false,
                list: []
            }
        }
    },
    created() {
        let self: any = this;
        Category.getCategoryList().then((body: any) => {
            if (body['code'] === 0) {
                self.addModal.list = body.data;
            }
        })
    },
    methods: {
        show(row: any) {
            let self: any = this;
            self.row = row;
            self.addModal.show = true;
        },
        onOk() {
            let self: any = this;
            self.$emit('on-ok', {...self.row});
        },
        clear() {
            let self: any = this;
            self.row.image = '';
            self.$forceUpdate();
        },
        uploadSuccess(url: string) {
            let self: any = this;
            self.row.image = url;
            self.$forceUpdate();
        }
    }
}
